import React from 'react';
import styled from 'styled-components';
import SplashImg from '../../resources/images/foundation/splash-overlay.png';
import SplashMobileImg from '../../resources/images/foundation/splash-overlay-mobile.png';

const SplashOverlay = styled.div`
    position: absolute;
    left: 0;
    bottom: -1px;
    width: 100%;
    height: 100%;
    max-height: 200px;
    display: block;
    background-image: url(${SplashMobileImg});
    background-repeat: repeat-x;
    background-size: contain;
    background-position: 0 100%;
    @media(min-width: 586px) {
      height: 185px;
      background-image: url(${SplashImg});
    }
`;

const OverlaySplash = () => {
  return (<>
      <SplashOverlay/>
    </>
  )
};

export default OverlaySplash;

import React, { Component } from "react"
import styled from "styled-components"
import fonts from "../../styles/fonts"
import { Row, Col, Container } from "styled-bootstrap-grid"
import { Fade } from "react-reveal"
import Img from "gatsby-image"
import BlueLineImage from "../../resources/images/horizontal-broken-line.svg"
import LifeboatsLogoImg from "../../resources/images/foundation/lifeboats-logo.svg"
//import NationalTrustLogoImg from "../../resources/images/foundation/nationaltrust-logo.svg"
import HorizontalGreyLineImg from "../../resources/images/foundation/horizontal-grey-line.svg"
import CountUp from "react-countup"

const IntroFoundationContainer = styled.div`
  width: 100%;
  background: white;
`
const ContentContainer = styled(Container)`
  padding: 56px 0 95px 0;
`
const ColStyled = styled(Col)`
  padding-left: 0;
  padding-right: 0;
`
const RowStyled = styled(Row)`
  margin-left: 0;
  margin-right: 0;
`
const BlueLineBox = styled.div`
  width: 100%;
  height: auto;
  text-align: center;
  margin-top: -10px;
`
const BlueLine = styled.img`
  width: 64px;
  height: auto;
  padding-top: 45px;
  @media (min-width: 768px) {
    width: 80px;
  }
`
const IntroText = styled.div`
  font-size: 15px;
  line-height: 21px;
  letter-spacing: 0;
  ${fonts.gilroyRegular};
  color: #222e3c;
  text-align: center;
  margin: auto;
`
const IntroTextBox = styled.div`
  max-width: 348px;
  margin: auto;
  @media (min-width: 768px) {
    max-width: 554px;
    text-align: left;
  }
`
const IntroImageFirst = styled(Img)`
  height: 100%;
  width: 294px;
  @media (min-width: 768px) {
    width: 553px;
  }
`
const IntroImageSecond = styled(Img)`
  height: 100%;
  width: 196px;
  @media (min-width: 768px) {
    width: auto;
  }
`
const IntroImagesBox = styled.div`
  position: relative;
  padding-top: 64px;
  padding-left: 34px;
  padding-right: 34px;
  @media (min-width: 768px) {
    padding-top: 98px;
    padding-left: 0;
    padding-right: 0;
  }
`
const SecondImageBox = styled.div`
  margin-top: -13.5%;
  margin-left: 44.5%;
  position: relative;
  z-index: 1;
  @media (min-width: 540px) {
    margin-top: -12.5%;
  }
  @media (min-width: 992px) {
    margin-top: -22.5%;
  }
`
const IntroNavyTitle = styled.div`
  color: #222e3c;
  font-size: 32px;
  letter-spacing: 2px;
  text-transform: uppercase;
  ${fonts.swissBlackExtended};
  margin-top: -3%;
  padding-right: 24px;
  width: auto;
  z-index: 2;
  position: relative;
  @media (min-width: 768px) {
    font-size: 62px;
    letter-spacing: 3px;
    padding-right: 0;
  }
`
const FoundationSum = styled.div`
  color: transparent;
  -webkit-text-stroke-width: 1.5px;
  -webkit-text-stroke-color: #9ddce2;
  letter-spacing: 2.9px;
  text-transform: uppercase;
  ${fonts.swissBlackExtended};
  font-size: 46px;
  padding-bottom: 8px;
  line-height: 34px;
  padding-top: 17px;
  @media (min-width: 767px) {
    font-size: 78px;
    line-height: 87px;
    letter-spacing: 5px;
    padding-bottom: 15px;
    -webkit-text-stroke-width: 1.5px;
    padding-top: 24px;
  }
`
const FoundationUnderline = styled.div`
  height: 7px;
  width: 280px;
  background-color: #9ddce2;
  @media (min-width: 767px) {
    height: 10px;
    width: 474px;
  }
`
const TopTextBox = styled.div`
  padding-top: 50px;
  padding-left: 34px;
  padding-right: 34px;
  font-size: 15px;
  line-height: 21px;
  letter-spacing: 0;
  ${fonts.gilroyRegular};
  color: #9ca8b5;
  @media (min-width: 768px) {
    margin-top: 58px;
    padding-top: 98px;
    max-width: 363px;
  }
  @media (min-width: 768px) and (max-width: 992px) {
    padding-left: 0;
    padding-top: 0;
  }
`
const BottomTextBox = styled.div`
  display: none;
  @media (min-width: 992px) {
    display: block;
    padding-left: 34px;
    padding-right: 34px;
    margin-top: 233px;
    font-size: 15px;
    line-height: 21px;
    letter-spacing: 0;
    ${fonts.gilroyRegular};
    color: #9ca8b5;
    max-width: 363px;
  }
`
const LogosBox = styled.div`
  padding-left: 34px;
  padding-right: 34px;
  padding-top: 40px;
  display: flex;
  @media (min-width: 768px) {
    padding-top: 65px;
  }
  @media (min-width: 768px) and (max-width: 992px) {
    padding-left: 0;
  }
`
const LifeboatsLogoImage = styled.img`
  padding-top: 23px;
  padding-right: 35.5px;
  /* margin-lefT: 20px; */
`
// const NationalTrustLogoImage = styled.img`
//     @media(min-width:992px){
//         padding-right: 35.5px;
//     }
// `
const HorizontalGreyLine = styled.img`
  display: none;
  @media (min-width: 992px) {
    display: block;
    z-index: 1;
  }
`

class IntroBlock extends Component {
  state = {
    countUpInView: false,
  }

  changeVisibility = () => {
    this.setState({
      countUpInView: true,
    })
  }

  render() {
    return (
      <IntroFoundationContainer>
        <ContentContainer>
          <RowStyled>
            <ColStyled>
              <IntroTextBox>
                <Fade duration={2000} delay={0}>
                  <IntroText>
                    Our respect for the sea and all who risk life and limb to
                    chart their own course knows no bounds. That’s why we’ve set
                    up the Lost at Sea Foundation, a charitable trust that
                    supports causes close to our heart.
                  </IntroText>
                  <BlueLineBox>
                    <BlueLine src={BlueLineImage} />
                  </BlueLineBox>
                </Fade>
              </IntroTextBox>
            </ColStyled>
          </RowStyled>
          <RowStyled>
            <ColStyled sm={12} lg={5} xsOrder={2} smOrder={2} lgOrder={1}>
              <TopTextBox>
                Our main beneficiary is the RNLI – The charity that saves lives
                at sea - 24 hours a day, 365 days a year. Their volunteer
                lifeboat crews provide a 24-hour rescue service in the UK and
                Ireland, and their seasonal lifeguards look after people on busy
                beaches. Not forgetting their Flood Rescue Team helping those
                affected by flooding. The RNLI crews and lifeguards have saved
                over 142,700 lives since 1824.
              </TopTextBox>
              <LogosBox>
                <LifeboatsLogoImage src={LifeboatsLogoImg} />
                <HorizontalGreyLine src={HorizontalGreyLineImg} />
              </LogosBox>
              <BottomTextBox>
                That’s why we pledge 5p from every single can sold to this
                amazing organisation.
              </BottomTextBox>
            </ColStyled>
            <ColStyled sm={12} lg={7} xsOrder={1} smOrder={1} lgOrder={2}>
              <IntroImagesBox>
                <IntroImageFirst
                  fluid={this.props.introImageFirst.childImageSharp.fluid}
                  alt={"Intro Image"}
                />
                <SecondImageBox>
                  <IntroImageSecond
                    fluid={this.props.introImageSecond.childImageSharp.fluid}
                    alt={"Intro Image"}
                  />
                </SecondImageBox>
                <IntroNavyTitle>Total raised to date</IntroNavyTitle>
                <Fade onReveal={() => this.changeVisibility()}>
                  <FoundationSum>
                    <CountUp
                      start={0}
                      end={this.state.countUpInView ? 1674 : 0}
                      separator=","
                      duration={2}
                      prefix="£"
                    />
                  </FoundationSum>
                </Fade>
                <Fade duration={750}>
                  <FoundationUnderline />
                </Fade>
              </IntroImagesBox>
            </ColStyled>
          </RowStyled>
        </ContentContainer>
      </IntroFoundationContainer>
    )
  }
}
export default IntroBlock

import React, { Component } from 'react';
import Layout from '../components/core/Layout';
import SEO from '../components/core/Seo';
import Hero from '../components/foundation/Hero';
import get from 'lodash/get';
import IntroBlock from "../components/foundation/Intro";
import ContactBlock from "../components/foundation/Contact";
import PrivateRoute from "../router/PrivateRoute";

class FoundationPage extends Component {
  render() {
    const path = this.props.location.pathname;
    const data = get(this, 'props.data');
    const {
      page,
      introImageFirst,
      introImageSecond,
      contactImage,
      contactImageDesktop
    } = data;


    return (
      <Layout>
        <SEO title={page.metaTitle}
             description={page.metaDescription}
             fullTitle={false}
             path={path}/>
        <Hero/>
        <IntroBlock introImageFirst={introImageFirst} introImageSecond={introImageSecond}/>
        <ContactBlock contactImage={contactImage} desktop={contactImageDesktop}/>
      </Layout>
    )
  }
}

function foundation(props) {
  return <PrivateRoute component={FoundationPage} {...props}/>
}

export default foundation;


export const pageQuery = graphql`
  query FoundationPageQuery {
    page: contentfulLandingPage(slug: { eq: "foundation" }) {
      id
      metaTitle
      metaDescription
    }
    introImageFirst: file(relativePath: { eq: "foundation/foundation_01.jpg" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 553) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    introImageSecond: file(relativePath: { eq: "foundation/foundation_02.jpg" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    contactImage: file(relativePath: { eq: "foundation/contact-image.jpg" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    contactImageDesktop: file(relativePath: { eq: "foundation/contact-opt.jpg" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 1296) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;

import React from "react"
import styled from "styled-components"
import fonts from "../../styles/fonts"

const StyledTitle = styled.div`
    text-align: center;
    padding-top: 15px;
    z-index: 1;
    @media(min-width: 586px){
        padding-top: 50px;
    }
`

const WhiteLine = styled.h1`
    text-transform: uppercase;
    color: white;
    letter-spacing: 2.2px;
    line-height: 46px;
    margin-bottom: 0px;
    ${fonts.swissBlackExtended}
    font-size: 34px;
    @media (min-width: 586px) {
        font-size: 50px;
    }
    @media (min-width: 768px) {
        font-size: 78px;
        letter-spacing: 5px;
    }
    @media (max-width: 400px) {
        font-size: 25px;
        letter-spacing: 1px;
    }  
`

const Title = (props) => {
  const { title } = props
  return (
    <StyledTitle>
      <WhiteLine>{title}</WhiteLine>
    </StyledTitle>
  )
}

export default Title;
import React from "react"
import styled from "styled-components"
import fonts from "../../styles/fonts"
import { Row, Col, Container } from "styled-bootstrap-grid"
import Img from "gatsby-image"
import WavesImg from "../../resources/images/waves.svg"

const ContactFoundationBackground = styled.div`
    width: 100%;
    background: #020913;
    padding-left: 0;
    padding-right: 0;
`

const ContentContainer = styled(Container)`
    padding: 117px 0 100px 0;
    @media(min-width: 992px){
        padding-top: 210px;
    }
`

const ContactContent = styled.div`
    padding-left: 31px;
    padding-right: 34px;
    @media(min-width: 992px){
        padding: 0;
    }
`

const ColStyled = styled(Col)`
    padding-left: 0;
    padding-right: 0;
`

const RowStyled = styled(Row)`
    margin-left: 0;
    margin-right: 0;
`

const ContactImage = styled(Img)`
    max-width: 305px;
    height: auto;
    margin-left: 45px;
    z-index: 0;
    margin-bottom: 50px;
    @media(min-width: 992px){
        max-width: 648px;
        height: auto;
    }
`

const Title = styled.div`
    position: absolute;
    top: -8%;
    color: white;
    z-index: 1;
    ${fonts.swissBlackExtended};
    text-transform: uppercase;
    font-size: 32px;
    letter-spacing: 2px;
    line-height: 34px;
    width: 257px;
    @media(min-width: 992px){
        font-size: 62px;
        letter-spacing: 4px;
        line-height: 64px;
        width: 394px;
        left: -37%;
    }
`

const Waves = styled.img`
    width: auto;
    height: 64px;
    transform: rotate(90deg);
`

const WavesBox = styled.div`
    position: absolute;
    left: 11%;
    top: 50%;
    z-index: 1;
    @media(min-width:768px){
        left: 6%;
        top: 60%;
    }
`

const TextBox = styled.div`
    color: #9CA8B5;
    ${fonts.gilroyRegular};
    font-size: 15px;
    line-height: 21px;
    @media(min-width: 992px){
        padding-top: 153px;
        padding-left: 100px;
        padding-right: 116px;
    }
`

const Email = styled.a`
    color: white;
    ${fonts.gilroyBold};
    text-decoration: none;
`

const Desktop = styled.div`
  display: none;
  @media(min-width:768px){
    display: block;
    }
`
const Mobile = styled.div`
  display: block;
  @media(min-width:768px){
    display: none;
    }
`

const ContactBlock = (props) => {
  return (
    <ContactFoundationBackground>
      <ContentContainer>
        <ContactContent>
          <RowStyled>
            <ColStyled lg={5} sm={12} xsOrder={2} smOrder={2} lgOrder={1}>
              <TextBox>If you would like some of our beer for a charitable event, email us at <Email href="mailto: ahoy@lostatsea.com">ahoy@lostatsea.com</Email> and
                we’ll endeavour to do what we can. </TextBox>
            </ColStyled>
            <ColStyled lg={7} sm={12} xsOrder={1} smOrder={1} lgOrder={2}>
              <Title>That’s not all</Title>
              <WavesBox>
                <Waves src={WavesImg}/>
              </WavesBox>
              <Mobile>
                <ContactImage fluid={props.contactImage.childImageSharp.fluid} alt={"Intro Image"}/>
              </Mobile>
              <Desktop>
                <ContactImage fluid={props.desktop.childImageSharp.fluid} alt={"Intro Image"}/>
              </Desktop>
            </ColStyled>
          </RowStyled>
        </ContactContent>
      </ContentContainer>
    </ContactFoundationBackground>
  )
}

export default ContactBlock


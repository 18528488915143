import React from 'react';
import styled from 'styled-components';
import Title from '../foundation/WhiteTitle';
import BackgroundImage from "gatsby-background-image";
import { graphql, StaticQuery } from 'gatsby';
import { Fade } from 'react-reveal';
import fonts from '../../styles/fonts';
import OverlaySplash from "../foundation/OverlaySplash"


const BackgroundStyled = styled.div`
    position: relative;
    height: 580px;
    margin-top: -97px;
    text-align: center;
    @media(max-width: 768px) {
        height: 420px;
        margin-top: -138px;
    }
`;

const BackgroundImageStyled = styled(BackgroundImage)`
  width: 100%;
  height: 100%;
  background-size: cover;
`;

const Subtitle = styled.p`
    color: white;
    text-transform: uppercase;
    ${fonts.gilroyBold};
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 1.2px;
    padding-top: 185px;
    @media(min-width: 992px){
         padding-top: 180px;
    }
`;


const HomeHero = () => (
  <StaticQuery query={graphql`
      query {
        background: file(relativePath: { eq: "foundation/hero-background.jpg" }) {
          childImageSharp {
            fluid(quality: 80, maxWidth: 5000) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
               render={data => {
                 const imageData = data.background.childImageSharp.fluid;

                 return (
                   <BackgroundStyled>
                     <BackgroundImageStyled fluid={imageData} critical={true} durationFadeIn={100}>
                         <Fade>
                           <Subtitle>the lost at sea</Subtitle>
                         </Fade>
                         <Fade duration={500} delay={250}>
                             <Title title={"Foundation"} />
                         </Fade>
                       <OverlaySplash />
                     </BackgroundImageStyled>
                   </BackgroundStyled>
                 )
               }
               }
  />);

export default HomeHero;
